import { useQuery } from '@apollo/client';
import { BILLING_ITEMS } from 'app/graphql';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { TabsContent } from 'components/ui/tabs';
import { Container, EmptyState, Image } from 'pages/Billing/styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import { format, setDate, addDays } from 'date-fns';
import { ActivityItem, columns } from './column';
import { DataTable } from 'components/ui/data-table';
import { capitalCase } from 'change-case';
import { ActivityDetailSheet } from '../ActivityDetailSheet';
import { ColumnFiltersState, PaginationState } from '@tanstack/react-table';
import { FacetOptions } from 'components/ui/data-table-toolbar';
import EmptyStateImg from 'assets/images/activities-empty.svg';
import {
  BillingItemParty,
  BillingItemResponse,
  BillingItemType,
} from 'app/graphql/generated/admin/graphql';
import { UTCDate } from '@date-fns/utc';
import { toZonedTime } from 'date-fns-tz';
import { ExportBillingItems } from './ExportBillingItems';
import { Skeleton } from 'components/ui/skeleton';
import { toast } from 'sonner';
import * as Sentry from '@sentry/react';

type Props = {
  value: string;
};

export const BillingActivityTabContent = ({ value }: Props) => {
  const { adminToken, billingPeriodSettings, isReadOnly } = useAppSelector(selectUserInfo) || {};
  const filtersRef = useRef<Record<string, string[]> | null>({});
  const [pageIndex, setPageIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [hasFilters, setHasFilters] = useState(false);
  const loadingTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [selectedItem, setSelectedItem] = useState<ActivityItem | undefined>();
  const [pageSize, setPageSize] = useState(20);

  const { startDate, endDate, formattedStartDate, formattedEndDate } = useMemo(() => {
    // using UTCDate to get the current date in UTC because data is stored in UTC time on the backend
    const now = new UTCDate();
    now.setHours(0, 0, 0, 0);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (billingPeriodSettings?.__typename === 'BillingPrePayment') {
      const startDate = new UTCDate(billingPeriodSettings.lastRefillDate);

      return {
        startDate: startDate.toISOString(),
        endDate: now.toISOString(),
        formattedStartDate: format(toZonedTime(startDate, timeZone), 'MMM do'),
        formattedEndDate: format(toZonedTime(now, timeZone), 'MMM do'),
      };
    } else if (billingPeriodSettings?.__typename === 'BillingPerPeriod') {
      const startDate = new UTCDate(billingPeriodSettings.startDate);
      const endDate = new UTCDate(billingPeriodSettings.endDate);

      return {
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        formattedStartDate: format(toZonedTime(startDate, timeZone), 'MMM do'),
        formattedEndDate: format(toZonedTime(endDate, timeZone), 'MMM do'),
      };
    } else if (billingPeriodSettings?.__typename === 'BillingPerGMV') {
      const startDate = new UTCDate(billingPeriodSettings.lastInvoiceDate);

      return {
        startDate: startDate.toISOString(),
        endDate: now.toISOString(),
        formattedStartDate: format(toZonedTime(startDate, timeZone), 'MMM do'),
        formattedEndDate: format(toZonedTime(now, timeZone), 'MMM do'),
      };
    }

    return {
      startDate: setDate(now, 1).toISOString(),
      endDate: addDays(setDate(now, 1), 30).toISOString(),
      formattedStartDate: format(toZonedTime(now, timeZone), 'MMM do'),
      formattedEndDate: format(toZonedTime(now, timeZone), 'MMM do'),
    };
  }, [billingPeriodSettings]);

  const [tableData, setTableData] = useState<ActivityItem[]>([]);
  const [totalPages, setTotalPages] = useState(1);
  const startCursorRef = useRef<string | undefined>();
  const endCursorRef = useRef<string | undefined>();

  const handleQueryData = (data: { billingItems: BillingItemResponse }) => {
    setTableData(transformTableData(data.billingItems, pageIndex, pageSize));
    endCursorRef.current = data?.billingItems.pageInfo.endCursor?.valueOf();
    startCursorRef.current = data?.billingItems.pageInfo.startCursor?.valueOf();
    setTotalPages(data?.billingItems.pageInfo.totalPages ?? 1);
  };

  const {
    fetchMore,
    refetch,
    loading: queryLoading,
  } = useQuery(BILLING_ITEMS, {
    context: {
      clientName: 'admin',
    },
    skip: !adminToken,
    variables: {
      filters: {
        startDate,
        endDate,
      },
      pagination: {
        first: pageSize,
      },
    },
    onCompleted: handleQueryData,
  });

  const getBillingPeriodLabel = () => {
    if (billingPeriodSettings?.__typename === 'BillingPrePayment') {
      const balance = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: billingPeriodSettings.currency,
      }).format(billingPeriodSettings.remainingBalance / 100);

      return `Remaining balance: ${balance}`;
    } else if (billingPeriodSettings?.__typename === 'BillingPerGMV') {
      const remainingGMV = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: billingPeriodSettings.currency,
      }).format(billingPeriodSettings.remainingGMV / 100);

      return `GMV Remaining to Threshold: ${remainingGMV}`;
    }

    return `${formattedStartDate} - ${formattedEndDate}`;
  };

  useEffect(() => {
    loadingTimeout.current = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(loadingTimeout.current);
      loadingTimeout.current = undefined;
    };
  });

  const handleRowClick = (row: ActivityItem) => {
    setSelectedItem(row);
    setIsOpen(true);
  };

  const handleColumnFiltersChange = async (filters: ColumnFiltersState) => {
    filtersRef.current = filters.reduce((acc, filter) => {
      return { ...acc, [`${filter.id}s`]: filter.value };
    }, {});

    const {
      data: { billingItems },
    } = await refetch({
      filters: {
        startDate,
        endDate,
        ...filtersRef.current,
      },
      pagination: {
        first: pageSize,
      },
    });

    endCursorRef.current = billingItems.pageInfo.endCursor?.valueOf();
    startCursorRef.current = billingItems.pageInfo.startCursor?.valueOf();

    setPageIndex(0);
    setTotalPages((prev) => {
      const newTotalPage = billingItems.pageInfo.totalPages?.valueOf();

      if (newTotalPage !== undefined && newTotalPage !== prev) {
        return newTotalPage;
      }
      return prev;
    });

    setHasFilters(Object.keys(filtersRef.current).length > 0);

    setTableData(transformTableData(billingItems, pageIndex, pageSize));
  };

  const handlePaginationChange = async ({
    pageIndex: newPageIndex,
    pageSize: newPageSize,
  }: PaginationState) => {
    try {
      setLoading(true);

      setPageIndex(newPageIndex);
      setPageSize(newPageSize);

      const {
        data: { billingItems },
      } = await fetchMore({
        variables: {
          filters: {
            startDate,
            endDate,
            ...filtersRef.current,
          },
          pagination: {
            first: newPageIndex < totalPages - 1 ? newPageSize : undefined,
            last: newPageIndex === totalPages - 1 ? newPageSize : undefined,
            after: newPageIndex > pageIndex ? endCursorRef.current : undefined,
            before:
              newPageIndex < pageIndex && newPageIndex !== 0 ? startCursorRef.current : undefined,
          },
        },
      });

      endCursorRef.current = billingItems.pageInfo.endCursor?.valueOf();
      startCursorRef.current = billingItems.pageInfo.startCursor?.valueOf();

      setTableData(transformTableData(billingItems, newPageIndex, newPageSize));
    } catch (e) {
      console.error('Error fetching more billing items:', e);
      Sentry.captureException(new Error('Error fetching more billing items', { cause: e }));
      toast.error('There was an error fetching more billing items', {
        position: 'bottom-right',
      });
    } finally {
      setLoading(false);
    }
  };

  const facetOptions = useMemo(() => {
    const facets: FacetOptions = {
      payer: { options: [] },
      receiver: { options: [] },
      type: { options: [] },
      orderId: { options: [], emptyLabel: 'Select to search for order id' },
    };
    const valueExists: Record<string, boolean> = {};
    const parties = [
      { label: 'Rye', value: BillingItemParty.Rye },
      { label: 'Merchant', value: BillingItemParty.Merchant },
      { label: 'Developer', value: BillingItemParty.Developer },
      { label: 'Affiliate', value: BillingItemParty.Affiliate },
    ];

    facets.payer.options = parties;
    facets.receiver.options = parties;
    facets.type.options = [
      { label: 'Platform Fee', value: BillingItemType.PlatformFee },
      { label: 'Fulfillment Fee', value: BillingItemType.FulfillmentFee },
      { label: 'Platform Fee Refund', value: BillingItemType.PlatformFeeRefund },
      { label: 'Refund', value: BillingItemType.Refund },
      { label: 'Developer Fulfilled', value: BillingItemType.DeveloperFulfilled },
      { label: 'Markup Commission', value: BillingItemType.MarkupCommission },
      { label: 'Discount Commission', value: BillingItemType.DiscountCommission },
      { label: 'Affiliate Commission', value: BillingItemType.AffiliateCommission },
      { label: 'Merchant Commission', value: BillingItemType.MerchantCommission },
      { label: 'Credit', value: BillingItemType.Credit },
      {
        label: 'Markup Commission Developer Share',
        value: BillingItemType.MarkupCommissionDeveloperShare,
      },
      {
        label: 'Discount Commission Developer Share',
        value: BillingItemType.DiscountCommissionDeveloperShare,
      },
      {
        label: 'Affiliate Commission Developer Share',
        value: BillingItemType.AffiliateCommissionDeveloperShare,
      },
      {
        label: 'Merchant Commission Developer Share',
        value: BillingItemType.MerchantCommissionDeveloperShare,
      },
      { label: 'Markup Commission Rye Share', value: BillingItemType.MarkupCommissionRyeShare },
      { label: 'Discount Commission Rye Share', value: BillingItemType.DiscountCommissionRyeShare },
      {
        label: 'Affiliate Commission Rye Share',
        value: BillingItemType.AffiliateCommissionRyeShare,
      },
      { label: 'Merchant Commission Rye Share', value: BillingItemType.MerchantCommissionRyeShare },
    ];

    for (const data of tableData) {
      for (const [key, value] of Object.entries(data)) {
        if (!facets[key]) {
          facets[key] = { options: [] };
        }

        if (key !== 'orderId') {
          continue;
        }

        if (typeof value === 'string' && !valueExists[value]) {
          facets[key].options.push({ label: `${value}`, value });
          valueExists[value] = true;
        }
      }
    }

    return facets;
  }, [tableData]);

  return (
    <TabsContent value={value}>
      <ActivityDetailSheet open={isOpen} onOpenChange={setIsOpen} item={selectedItem} />
      <Container>
        <Card className="mt-[28px]">
          <CardHeader className="flex-auto flex-row justify-between">
            <div className="space-y-1">
              {loading || queryLoading ? (
                <Skeleton className="h-4 w-[24px]" />
              ) : (
                <CardTitle>{getBillingPeriodLabel()}</CardTitle>
              )}
              <CardDescription>List of billing Items for current billing period</CardDescription>
            </div>
          </CardHeader>
          <CardContent>
            <DataTable
              loading={loading || queryLoading}
              columns={columns}
              data={tableData}
              onRowClicked={handleRowClick}
              pageSize={pageSize}
              manualPagination={true}
              pageIndex={pageIndex}
              pageCount={totalPages}
              onPaginationChange={handlePaginationChange}
              hiddenFacets={['id', 'date', 'amount']}
              facetOptions={facetOptions}
              onColumnFiltersChange={handleColumnFiltersChange}
              toolbarItems={
                !isReadOnly && <ExportBillingItems startDate={startDate} endDate={endDate} />
              }
              emptyState={
                <EmptyState>
                  <Image src={EmptyStateImg} />
                  <CardTitle>
                    {hasFilters
                      ? 'No items found with the selected filters'
                      : 'No billing items yet'}
                  </CardTitle>
                </EmptyState>
              }
            />
          </CardContent>
        </Card>
      </Container>
    </TabsContent>
  );
};

const transformTableData = (
  billingItems: BillingItemResponse | undefined | null,
  newPageIndex: number,
  newPageSize: number,
): ActivityItem[] => {
  if (!billingItems?.edges) return [];

  return (
    billingItems.edges.map((edge, index) => ({
      id: index + 1 + newPageIndex * newPageSize,
      amount: `${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: edge.node.amount.currency,
      }).format(edge.node.amount.value / 100)} ${edge.node.amount.currency}`,
      date: format(new Date(edge.node.createdAt), 'MM/dd/yyyy'),
      payer: capitalCase(edge.node.payer),
      receiver: capitalCase(edge.node.receiver),
      type: capitalCase(edge.node.type),
      typeEnum: edge.node.type,
      orderId: edge.node.orderId,
      billingSetting: edge.node.billingSetting,
      volumeTiers: edge.node.billingSetting.volumeTiers,
      amountCents: edge.node.amount.value,
    })) ?? []
  );
};

import { useEffect, useRef, useState } from 'react';

import FormRequestBlock from 'components/FormRequestBlock';
import { useAppDispatch, useAppSelector } from 'app/store';
import { selectUserInfo, selectUserInfoLoading } from 'app/store/user';

import './style.scss';
import { useSearchParams } from 'react-router-dom';
import { checkAmazonAuth, ryeOAuth } from 'app/amazon/auth';
import { AMAZON_AUTH_URL } from 'app/amazon/constants';
import FormInput from 'components/FormInput';
import FormButton from 'components/FormButton';
import { fetchAmazonBusinessAccounts } from 'app/store/amazonBusinessAccounts';
import {
  AmazonBusinessConfig,
  AmazonBusinessOauthStatus,
  BillingItemParty,
} from 'app/graphql/generated/admin/graphql';
import { ADMIN_USER, updateAdminUser } from 'app/graphql';
import { useQuery } from '@apollo/client/react';
import { toast } from 'sonner';
import { isEmail } from 'class-validator';
import * as Sentry from '@sentry/react';

const AmazonBusiness = ({
  amazonBusinessConfig,
  userId,
}: {
  amazonBusinessConfig: AmazonBusinessConfig;
  userId: string;
}) => {
  const dispatch = useAppDispatch();

  const isUserInfoLoading = useAppSelector(selectUserInfoLoading);
  const { adminToken, isReadOnly } = useAppSelector(selectUserInfo) || {};

  const [formLoading, setFormLoading] = useState(true);
  const [isRevoking, setRevoking] = useState(false);
  const [inputValue, setInputValue] = useState(amazonBusinessConfig.groupId ?? '');
  const [amazonAccountEmail, setAmazonAccountEmail] = useState('');
  const [amazonEmail, setAmazonEmail] = useState(amazonBusinessConfig.email ?? '');
  const [oauthStatus, setOauthStatus] = useState<AmazonBusinessOauthStatus | undefined | null>(
    amazonBusinessConfig.oauthStatus,
  );
  const codeSet = useRef(false);
  const checkingStatus = useRef(false);
  const authInterval = useRef<ReturnType<typeof setInterval>>();
  const loadingTimeout = useRef<ReturnType<typeof setTimeout>>();

  const [searchParams, setSearchParams] = useSearchParams();

  const { refetch } = useQuery(ADMIN_USER, {
    skip: !adminToken,
    context: {
      clientName: 'admin',
    },
  });

  const authenticate = async (code: string) => {
    await ryeOAuth({ code, adminToken });
    const res = await checkAmazonAuth(adminToken, null);
    setOauthStatus(res.data.amazonBusinessAuthStatus.status);
  };

  const checkAuthStatus = async (showAuthRevokedMessage?: boolean) => {
    checkingStatus.current = true;
    const [data, res] = await Promise.all([refetch(), checkAmazonAuth(adminToken, null)]);
    const amazonConfig = data?.data.user.data?.mainAmazonConfig;

    if (
      res.data.amazonBusinessAuthStatus.status === AmazonBusinessOauthStatus.Revoked &&
      amazonConfig?.oauthStatus === AmazonBusinessOauthStatus.Active
    ) {
      clearInterval(authInterval.current);
      authInterval.current = undefined;
      setOauthStatus(undefined);
      setAmazonAccountEmail('');
      setRevoking(false);
      setFormLoading(false);
      if (showAuthRevokedMessage) {
        toast.error(
          'Your Amazon Business account is no longer authenticated. Please authenticate again.',
          {
            position: 'bottom-right',
          },
        );
      }

      checkingStatus.current = false;
    }
  };

  const handleRevoke = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isReadOnly) return;

    window.open(AMAZON_AUTH_URL, '_blank');
    setRevoking(true);
    setFormLoading(true);
    authInterval.current = setInterval(async () => {
      try {
        await checkAuthStatus();
      } catch {
        // catch different error codes
      }
    }, 1000);

    // after 30 seconds stop polling to check if auth is revoked
    loadingTimeout.current = setTimeout(() => {
      clearInterval(authInterval.current);
      authInterval.current = undefined;
      setFormLoading(false);
    }, 1000 * 30);
  };

  const handleAuthentication = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();
    const email = amazonAccountEmail.trim();
    if (!email || !isEmail(email)) {
      toast.error('Please enter a valid email address', {
        position: 'bottom-right',
      });
      return;
    }

    try {
      await updateAdminUser({
        adminToken,
        updates: {
          amazonBusinessConfig: {
            parentAccountEmail: email,
            payer: BillingItemParty.Developer,
            groupId: '',
            email: '',
          },
        },
      });
    } catch (error) {
      toast.error('Amazon config could not be updated', {
        position: 'bottom-right',
      });
      Sentry.captureException(error);
    }

    window.location.href = AMAZON_AUTH_URL;
  };

  const handleGroupIdSave = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (isReadOnly) return;

    setFormLoading(true);
    const data = await refetch();
    const groupId = inputValue.trim();
    const email = amazonEmail.trim();

    const amazonConfig = data?.data.user.data?.mainAmazonConfig;

    if (groupId && email && amazonConfig) {
      try {
        await updateAdminUser({
          adminToken,
          updates: { amazonBusinessConfig: { groupId, email } },
        });
      } catch (error) {
        toast.error('Amazon config could not be updated', {
          position: 'bottom-right',
        });
      }
    }

    loadingTimeout.current = setTimeout(() => {
      setFormLoading(false);
    }, 1000);
  };

  useEffect(() => {
    const code = searchParams.get('code');
    if (code && !codeSet.current) {
      codeSet.current = true;
      setSearchParams();
      authenticate(code);
    }

    return () => {
      clearInterval(authInterval.current);
      clearTimeout(loadingTimeout.current);
    };
  }, []);

  useEffect(() => {
    dispatch(fetchAmazonBusinessAccounts(userId));

    setFormLoading(!amazonBusinessConfig && isUserInfoLoading);
  }, [amazonBusinessConfig, isUserInfoLoading]);

  useEffect(() => {
    const code = searchParams.get('code');
    if (!code && !checkingStatus.current) {
      checkAuthStatus(true);
    }
  }, []);

  return (
    <FormRequestBlock
      styles={{ form: { flexDirection: 'column', gap: '10px' } }}
      title={'Amazon OAuth Access'}
      disabled={isReadOnly}
      text={
        <>
          {!oauthStatus || oauthStatus === AmazonBusinessOauthStatus.Revoked
            ? 'Authorize Rye to place orders using your own Amazon Business Account'
            : 'Update your groupId or email to the groupId and email address associated with your Amazon Account in order to place orders via Rye.'}
        </>
      }
      loading={formLoading}
    >
      <div className="amazon-business-form">
        {oauthStatus !== 'ACTIVE' ? (
          <>
            <div className="amazon-business-body">
              <FormInput
                id="amazon-business-input"
                className="amazon-business-input"
                type="text"
                disabled={isReadOnly}
                value={amazonAccountEmail}
                placeholder="Amazon Account Login Email"
                onChange={(value) => setAmazonAccountEmail(value)}
              />

              <FormButton disabled={formLoading || isReadOnly} onClick={handleAuthentication}>
                Authenticate
              </FormButton>
            </div>
          </>
        ) : (
          <>
            <div className="amazon-business-body">
              <FormInput
                id="amazon-business-input"
                className="amazon-business-input"
                type="text"
                disabled={isReadOnly}
                value={inputValue}
                placeholder="GroupId"
                onChange={(value) => setInputValue(value)}
              />
              <FormInput
                id="amazon-business-input-email"
                className="amazon-business-input"
                type="text"
                value={amazonEmail}
                placeholder="Email"
                disabled={isReadOnly}
                onChange={(value) => setAmazonEmail(value)}
              />

              <FormButton disabled={formLoading || isReadOnly} onClick={handleGroupIdSave}>
                Save
              </FormButton>
              <FormButton
                className={`amazon-business-oauth ${isRevoking ? 'revoking' : ''}`}
                onClick={handleRevoke}
                disabled={isReadOnly}
              >
                Revoke
              </FormButton>
            </div>
          </>
        )}
      </div>
    </FormRequestBlock>
  );
};

export default AmazonBusiness;

import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from '@radix-ui/react-icons';
import { PaginationState, Table } from '@tanstack/react-table';

import { Button } from './button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from './select';

interface DataTablePaginationProps<TData> {
  table: Table<TData>;
  showSelectionCount?: boolean;
  hidePageSkipButtons?: boolean;
  hideTotalPageCount?: boolean;
  hasNextPage?: boolean;
  hasPreviousPage?: boolean;
  pageSize?: number;
}

export type DataTablePaginationState = PaginationState & {
  direction?: 'next' | 'previous' | 'none';
};

export function DataTablePagination<TData>({
  table,
  showSelectionCount,
  hidePageSkipButtons,
  hideTotalPageCount,
  hasNextPage,
  hasPreviousPage,
  pageSize,
}: DataTablePaginationProps<TData>) {
  const hasNextPageState = hasNextPage ?? table.getCanNextPage();
  const hasPreviousPageState = hasPreviousPage ?? table.getCanPreviousPage();

  const handleNextPage = () => {
    if (hasNextPageState) {
      const index = table.getState().pagination.pageIndex + 1;
      table.setPagination((state) => ({ ...state, pageIndex: index, direction: 'next' }));
    }
  };

  const handlePreviousPage = () => {
    if (hasPreviousPageState) {
      const index = table.getState().pagination.pageIndex - 1;
      table.setPagination((state) => ({ ...state, pageIndex: index, direction: 'previous' }));
    }
  };

  const pageSizes = new Set([10, 20, 30, 40, 50]);
  if (pageSize) {
    pageSizes.add(pageSize);
  }

  return (
    <div
      className={`flex items-center ${showSelectionCount ? 'justify-between' : 'justify-end'} px-2`}
    >
      {showSelectionCount && (
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of{' '}
          {table.getFilteredRowModel().rows.length} row(s) selected.
        </div>
      )}
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">Rows per page</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value) => {
              table.setPageSize(Number(value));
            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {[...pageSizes].sort().map((pageSize: number) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {!hideTotalPageCount && (
          <div className="flex max-w-[300px] items-center justify-center text-sm font-medium">
            Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount() || 1}
          </div>
        )}
        <div className="flex items-center space-x-2">
          {!hidePageSkipButtons && (
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(0)}
              disabled={!hasPreviousPageState}
            >
              <span className="sr-only">Go to first page</span>
              <DoubleArrowLeftIcon className="h-4 w-4" />
            </Button>
          )}
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={handlePreviousPage}
            disabled={!hasPreviousPageState}
          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={handleNextPage}
            disabled={!hasNextPageState}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          {!hidePageSkipButtons && (
            <Button
              variant="outline"
              className="hidden h-8 w-8 p-0 lg:flex"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!hasNextPageState}
            >
              <span className="sr-only">Go to last page</span>
              <DoubleArrowRightIcon className="h-4 w-4" />
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}

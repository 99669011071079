import { graphQLClient } from 'app/graphql/client';
import { RYE_AUTH_URL } from './constants';
import { AUTH_AMAZON, CHECK_AMAZON_AUTH } from 'app/graphql';

export const ryeOAuth = async ({
  code,
  adminToken,
  apiKey,
}: {
  code: string;
  apiKey?: string;
  adminToken?: string | null;
}) => {
  graphQLClient.set(apiKey, adminToken);
  return graphQLClient.get().mutate({
    mutation: AUTH_AMAZON,
    context: {
      clientName: 'admin',
    },
    variables: {
      input: { code },
    },
  });
};

// TODO: Remove this in favor of a revoke oauth endpoint on the server side
export const checkAmazonAuth = async (
  adminToken: string | null | undefined,
  apiKey: string | null,
) => {
  graphQLClient.set(apiKey, adminToken);
  return graphQLClient.get().query({
    query: CHECK_AMAZON_AUTH,
    context: {
      clientName: 'admin',
    },
  });
};

export const requestAccountAccess = async (
  developerId: string,
  email: string,
  isAdditionalAccount: boolean,
) => {
  const myHeaders = new Headers();
  myHeaders.append('Content-Type', 'application/json');

  const res = await fetch(`${RYE_AUTH_URL}/amazon/request_account_access`, {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({ developerId, email, isAdditionalAccount }),
  });

  return await res.text();
};

import { useQuery } from '@apollo/client';
import { INVOICES } from 'app/graphql';
import { useAppSelector } from 'app/store';
import { selectUserInfo } from 'app/store/user';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from 'components/ui/card';
import { TabsContent } from 'components/ui/tabs';
import { Container, EmptyState, Image } from 'pages/Billing/styles';
import { useEffect, useRef, useState } from 'react';
import { addDays, format, isValid } from 'date-fns';
import { columns, InvoiceItem } from './column';
import { DataTable } from 'components/ui/data-table';
import { capitalCase } from 'change-case';
import EmptyStateImg from 'assets/images/no-invoices.svg';
import { InvoicesQuery } from 'app/graphql/generated/admin/graphql';
import { Separator } from 'components/ui/separator';
import { BalanceContent, BillingPeriodContent, Label } from './styles';
import { Button } from 'components/ui/button';
import { InvoiceDialog } from '../InvoiceDialog';
import { Row } from '@tanstack/react-table';
import { ExportInvoices } from './ExportInvoices';
import { UTCDate } from '@date-fns/utc';
import { DataTablePaginationState } from 'components/ui/data-table-pagination';
import { toast } from 'sonner';
import * as Sentry from '@sentry/react';

type Props = {
  value: string;
};

// Page size value coming in will be 1 less than what we send to the api
// because we allocate the first element for the current invoice and then
// show the rest of the invoices in the table
const API_PAGE_SIZE = 13;

export const BillingInvoiceTabContent = ({ value }: Props) => {
  const { adminToken, billingPeriodSettings } = useAppSelector(selectUserInfo) || {};
  const [isOpen, setOpen] = useState(false);
  const [isExportOpen, setOpenExport] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<InvoiceItem | undefined>();
  const [loading, setLoading] = useState(true);
  const [apiPageSize, setApiPageSize] = useState(API_PAGE_SIZE);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);
  const beforeCursorRef = useRef<string | undefined>();
  const afterCursorRef = useRef<string | undefined>();
  const [tableData, setTableData] = useState<InvoiceItem[]>([]);
  const [currentInvoice, setCurrentInvoice] = useState<InvoiceItem | undefined>();

  const [selectedItem, setSelectedItem] = useState<InvoiceItem | undefined>();

  const handleQueryData = (data: InvoicesQuery) => {
    const tableData = transformTableData(data, billingPeriodSettings?.gracePeriodDays ?? 15);
    setTableData(tableData);
    setCurrentInvoice(tableData[0]);
    afterCursorRef.current = data.invoices.pageInfo.endCursor?.valueOf();
    beforeCursorRef.current = data.invoices.pageInfo.startCursor?.valueOf();
    setHasNextPage(data.invoices.pageInfo.hasNextPage ?? false);
    setHasPreviousPage(data.invoices.pageInfo.hasPreviousPage ?? false);
  };

  const {
    data,
    fetchMore,
    loading: queryLoading,
  } = useQuery(INVOICES, {
    context: {
      clientName: 'admin',
    },
    skip: !adminToken,
    variables: {
      pagination: {
        first: apiPageSize,
      },
    },
    onCompleted: handleQueryData,
  });

  const dataTablePageSize = apiPageSize - 1;

  const selectedInvoiceData = selectedItem
    ? data?.invoices.edges[selectedItem.index]?.node
    : undefined;

  const handleActionItemClicked = (row: Row<InvoiceItem>) => {
    setSelectedInvoice(row.original);
    setOpenExport(true);
  };

  const handleCurrentInvoiceReport = (invoice: InvoiceItem) => {
    setSelectedInvoice(invoice);
    setOpenExport(true);
  };

  useEffect(() => {
    const loadingTimeoutId = setTimeout(() => {
      setLoading(false);
    }, 1000);

    return () => {
      clearTimeout(loadingTimeoutId);
    };
  }, [data]);

  const handleRowClick = (row: InvoiceItem) => {
    setSelectedItem(row);
    setOpen(true);
  };

  const handleViewDetails = () => {
    setSelectedItem(currentInvoice);
    setOpen(true);
  };

  const getCurrentStatusTag = () => {
    switch (currentInvoice?.status) {
      case 'Paid':
        return 'Invoice has been paid';
      case 'Unpaid':
        return 'Invoice finalized and waiting payment';
      case 'Void':
        return 'Invoice has been voided';
      default:
        return 'Invoice is being finalized';
    }
  };

  const getDueDate = (invoice: InvoiceItem) => {
    const dueDate = invoice.dueDate;
    const chargeDate = invoice.chargeDate;

    // Check charge date first because Due Date is set to charge date if due date does not exist
    if (chargeDate && isValid(new UTCDate(chargeDate))) {
      return `Charging payment method on ${format(new UTCDate(chargeDate), 'MMMM do')}`;
    }

    if (!isValid(new UTCDate(dueDate))) {
      return 'No due date';
    }

    return `Due ${format(new UTCDate(dueDate), 'MMMM do')}`;
  };

  const handlePaginationChange = async ({
    pageSize: newPageSize,
    direction,
  }: DataTablePaginationState) => {
    setLoading(true);

    if (newPageSize !== dataTablePageSize) {
      // Page size value coming in will be 1 less than what we send to the api
      // because we allocate the first element for the current invoice and then
      // show the rest of the invoices in the table
      setApiPageSize(newPageSize + 1);
    }

    try {
      const { data } = await fetchMore({
        variables: {
          pagination: {
            first: newPageSize !== apiPageSize ? newPageSize : apiPageSize,
            after: direction === 'next' ? afterCursorRef.current : undefined,
            before: direction === 'previous' ? beforeCursorRef.current : undefined,
          },
        },
      });

      afterCursorRef.current = data.invoices.pageInfo.endCursor?.valueOf();
      beforeCursorRef.current = data.invoices.pageInfo.startCursor?.valueOf();

      setTableData(transformTableData(data, billingPeriodSettings?.gracePeriodDays ?? 15));
      setHasNextPage(data.invoices.pageInfo.hasNextPage || false);
      setHasPreviousPage(data.invoices.pageInfo.hasPreviousPage || false);
    } catch (e) {
      console.error('Error fetching more invoices:', e);
      Sentry.captureException(new Error('Error fetching more invoices', { cause: e }));
      toast.error('There was an error fetching more invoices', {
        position: 'bottom-right',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <TabsContent value={value}>
      <Container>
        <InvoiceDialog
          open={isOpen}
          invoice={selectedInvoiceData}
          rowData={selectedItem}
          onOpenChange={setOpen}
        />
        {selectedInvoice && (
          <ExportInvoices
            isOpen={isExportOpen}
            onOpenChange={setOpenExport}
            invoiceItem={selectedInvoice}
          />
        )}
        {currentInvoice && (
          <Card className="mb-[20px] mt-[20px] inline-flex h-[150px] p-[24px]">
            <CardContent className="flex flex-row items-center gap-[70px] p-0">
              <BillingPeriodContent>
                <Label>Billing Period</Label>
                <CardTitle className="font-normal">{currentInvoice.billingPeriod}</CardTitle>
                <CardDescription>{getCurrentStatusTag()}</CardDescription>
              </BillingPeriodContent>

              <Separator orientation="vertical" className="h-[100%]" />

              <BalanceContent>
                <Label>Balance</Label>
                <CardTitle className="font-normal">
                  {currentInvoice.amountDue.split(' ')[0]}
                </CardTitle>
                <CardDescription>{getDueDate(currentInvoice)}</CardDescription>
              </BalanceContent>
              <div className="flex flex-col gap-2">
                <Button onClick={handleViewDetails}>View Invoice Details</Button>
                <Button
                  variant="outline"
                  onClick={() => handleCurrentInvoiceReport(currentInvoice)}
                >
                  Export Billing Period
                </Button>
              </div>
            </CardContent>
          </Card>
        )}
        <Card>
          <CardHeader className="flex-auto flex-row justify-between">
            <div className="space-y-1">
              <CardTitle>Invoice History</CardTitle>
              <CardDescription>List of past invoices</CardDescription>
            </div>
          </CardHeader>
          <CardContent>
            <DataTable
              loading={loading || queryLoading}
              columns={columns}
              onRowActionClick={handleActionItemClicked}
              rowActions={[
                {
                  label: 'Export billing period',
                  id: 'export',
                },
              ]}
              data={tableData.filter((item) => item.id !== currentInvoice?.id)}
              hideToolbar={true}
              onRowClicked={handleRowClick}
              emptyState={
                <EmptyState>
                  <Image style={{ width: '200px' }} src={EmptyStateImg} />
                  <CardTitle>{'Invoice history is empty...'}</CardTitle>
                </EmptyState>
              }
              onPaginationChange={handlePaginationChange}
              pageSize={dataTablePageSize}
              manualPagination={true}
              hidePageSkipButtons={true}
              hideTotalPageCount={true}
              hasNextPage={hasNextPage}
              hasPreviousPage={hasPreviousPage}
            />
          </CardContent>
        </Card>
      </Container>
    </TabsContent>
  );
};

function transformTableData(
  invoices: InvoicesQuery | undefined | null,
  gracePeriodDays: number,
): InvoiceItem[] {
  if (!invoices) return [];
  return invoices.invoices.edges.map((edge, index) => {
    // these dates are utc timestamps
    const startDate = new Date(edge.node.billingPeriod.startDate);
    const endDate = new Date(edge.node.billingPeriod.endDate);
    const numberArray = edge.node.number.split('-');
    const invoiceId =
      numberArray[numberArray.length - 1] || `Draft_${new Date(edge.node.createdAt).getTime()}`;
    return {
      index,
      id: invoiceId,
      billingPeriod: `${format(startDate, 'MMM do, yyyy')} - ${format(endDate, 'MMM do, yyyy')}`,
      amountDue: `${new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: edge.node.amount.currency,
      }).format(edge.node.amount.value / 100)} ${edge.node.amount.currency}`,
      dueDate: edge.node.dueDate
        ? format(new Date(edge.node.dueDate), 'MM/dd/yyyy')
        : edge.node.chargeDate
        ? // Use charge date for auto pay
          format(new Date(edge.node.chargeDate), 'MM/dd/yyyy')
        : format(addDays(new Date(endDate), gracePeriodDays), 'MM/dd/yyyy'),
      status: capitalCase(edge.node.status),
      startDate: edge.node.billingPeriod.startDate,
      endDate: edge.node.billingPeriod.endDate,
      chargeDate: edge.node.chargeDate,
    };
  });
}
